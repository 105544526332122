import React from "react"
import { Flex, Text } from "@chakra-ui/core"
import PropTypes from "prop-types"

const NewsPreview = props => (
  <Flex
    direction="row"
    wrap={["wrap", null, "nowrap", null]}
    justifyContent="center"
    my={[5, null, 2, null]}
  >
    <Flex direction="column" ml={4}>
      <Text
        mb={2}
        fontSize="2xl"
        fontWeight="bold"
        as="a"
        href={props.slug}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.headline}
      </Text>
      <Text mb={2}>{props.author}</Text>
      <Text>{props.excerpt}</Text>
    </Flex>
  </Flex>
)

NewsPreview.propTypes = {
  headline: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

export default NewsPreview
