import React, { Fragment } from "react"
import {
  Box,
  Heading,
  Flex,
  Stack,
  Button,
  IconButton,
  Text,
} from "@chakra-ui/core"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import NewsPreview from "../components/NewsPreview"
import makeBackgroundImageArray from "../helpers/makeBackgroundImageArray"

const NewsPagination = props => {
  return (
    <Layout location={props.location} bg={makeBackgroundImageArray("news")}>
      <Box pt={12} maxW="800px" my={20}>
        <Heading fontSize="6xl" as="h1" fontWeight="bold">
          <FormattedMessage id="news.heading" />
        </Heading>
        <Text fontSize="lg" my={3} maxW="600px">
          <FormattedMessage id="news.subheading_1" />
        </Text>
      </Box>
      <Box my={8}>
        <Heading fontSize="4xl" fontWeight="bold" as="h2" my={5}>
          <FormattedMessage id="homepage.news" />
        </Heading>
        <Stack mr={4}>
          {props.data.allContentfulBlogPost.edges
            .map(edge => edge.node)
            .map(node => (
              <NewsPreview
                author={node.author}
                imageFixed={node.image.fixed}
                headline={node.headline}
                excerpt={node.excerpt.content}
                slug={node.slug}
              />
            ))}
        </Stack>
        <Flex
          direction="row"
          maxWidth="40vmax"
          width="100%"
          justifyContent="space-around"
          alignItems="center"
          mx="auto"
          mt={6}
        >
          {props.pageContext.currentPage > 1 && (
            <IconButton
              as={Link}
              to={`../${props.pageContext.currentPage - 1}`}
              variantColor="teal"
              variant="ghost"
              icon="chevron-left"
            />
          )}
          {Array.from({ length: props.pageContext.numPages }).map((_, i) => {
            if (
              i === props.pageContext.currentPage || // Show page if it's the current page
              Math.abs(i + 1 - props.pageContext.currentPage) <= 1 || // Show pages around the current page
              i === 0 || // Show first page
              i === props.pageContext.numPages - 1 // Show last page
            ) {
              return (
                <Fragment key={i}>
                  {i === props.pageContext.numPages - 1 &&
                    props.pageContext.currentPage <
                      props.pageContext.numPages - 2 && <Box>&hellip;</Box>}
                  <Button
                    as={Link}
                    to={`../${i + 1}`}
                    variantColor="teal"
                    variant={
                      props.pageContext.currentPage === i + 1
                        ? "solid"
                        : "ghost"
                    }
                  >
                    {i + 1}
                  </Button>
                  {i === 0 && props.pageContext.currentPage > 3 && (
                    <Box>&hellip;</Box>
                  )}
                </Fragment>
              )
            } else {
              return <Fragment key={i} />
            }
          })}
          {props.pageContext.currentPage < props.pageContext.numPages && (
            <IconButton
              as={Link}
              to={`../${props.pageContext.currentPage + 1}`}
              variantColor="teal"
              variant="ghost"
              icon="chevron-right"
            />
          )}
        </Flex>
      </Box>
    </Layout>
  )
}

export default NewsPagination

export const query = graphql`
  query blogListPagination($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: createdAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          slug
          headline
          author
          createdAt
          excerpt {
            content: excerpt
          }
          image {
            fixed(height: 240, width: 350, cropFocus: CENTER) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`
